<template>
  <div class="infinite-characters">
    <div v-if="characters && characters.length" class="characters">
      <div v-if="listLayout">
        <CharacterCard
          :limited="limited"
          :hideTypeTag="hideTypeTag"
          v-for="(character, index) of characters"
          :key="character?.id || index"
          :horizontal="true"
          class="mt-2"
          :character="character"
          :char-comments-count="get(charCommentsCount, `${get(character, 'id')}.comments_count`) || 0"
          :showCharacterTags="showCharacterTags"
          :readOnly="readOnly"
        />
      </div>
      <Grid v-else :layout="layout" :xl="4" :lg="3" :md="3" :sm="2" :scrollSm="false">
        <CharacterCard
          :limited="limited"
          :hideTypeTag="hideTypeTag"
          v-for="(character, index) of characters"
          :key="character?.id || index"
          :horizontal="false"
          class="grid-item"
          :character="character"
          :char-comments-count="get(charCommentsCount, `${get(character, 'id')}.comments_count`) || 0"
          :showCharacterTags="showCharacterTags"
          :readOnly="readOnly"
        />
      </Grid>
      <ion-button v-if="nextPageExists" class="clickable" :disabled="loadingMore" @click="requestLoadMore"
        >Load More</ion-button
      >
      <p class="no-data" v-else-if="!nextPageExists && pagination">End of list</p>
    </div>
    <div v-else-if="loading">
      <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
        <ion-skeleton-text v-for="(_, index) of [0, 1, 2, 3, 4]" :key="index" animated class="grid-item small-card" />
      </Grid>
    </div>
    <div v-else-if="pagination" class="no-data">No Characters</div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import { Character } from '@/shared/types/static-types';
import constants from '@/shared/statics/constants';
import { getCommentsCount } from '@/shared/actions/comments';

const emit = defineEmits(['load']);
const layout = ref('grid');

const props = defineProps({
  hideTypeTag: {
    type: Boolean,
    default: false,
  },
  characters: {
    type: Array as PropType<Character[]>,
    default: () => [],
  },
  paging: {
    type: Object,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  loadingMore: {
    type: Boolean,
    required: true,
  },
  layoutChanger: {
    type: Boolean,
  },
  pagination: {
    type: Boolean,
    default: true,
  },
  limited: {
    type: Boolean,
  },
  showCharacterTags: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  listLayout: {
    type: Boolean,
    default: false,
  }
});

const characters = toRef(props, 'characters');
const showCharacterTags = toRef(props, 'showCharacterTags');
const listLayout = toRef(props, 'listLayout');
const charCommentsCount = ref({});
const nextPageExists = computed(() => {
  return !!props.paging?.next;
});

const fetchCharsCommentCounts = async () => {
  const resp = await getCommentsCount(map(characters.value, 'id') as string[], constants.commentedAsTypes.CHARACTER);
  charCommentsCount.value = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
};
watch(
  characters,
  () => {
    fetchCharsCommentCounts();
  },
  { immediate: true, deep: true }
);

const requestLoadMore = () => {
  emit('load');
};
</script>

<style scoped lang="sass">
.characters
  color: #214163
.small-card
  aspect-ratio: 1
  border-radius: 12px
</style>
